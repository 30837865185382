@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

@font-face {
  font-family: 'u1d000';
  /* src: url("u1d000.woff"); */
  font-style: normal;
}

html,
body {
  overflow-x: hidden;
  overscroll-behavior-y: contain;
  font-family: 'Manrope', sans-serif;
}
body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

img {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  pointer-events: none;
}

html,
body,
#root {
  height: 100%;
  min-height: 100%;
}
